import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import ProjectPageHighlight from "../components/ProjectPageHighlight"

export default function ProjectTemplate({ data, pageContext }) {
  const {
    title,
    description,
    summary,
    highlightVideo,
    highlightImage,
    theme,
    credits,
    links,
  } = data.file.childMdx.frontmatter
  const metaImage =
    data.file.childMdx.frontmatter.highlightImage.childImageSharp.resize.src

  let nextSlug = []
  let nextTitle = []

  for (let i = 0; i < data.allFile.edges.length; i++) {
    if (data.allFile.edges[i].next === null) {
      nextSlug.push(data.allFile.edges[0].node.name)
      nextTitle.push(data.allFile.edges[0].node.childMdx.frontmatter.title)
    } else {
      nextSlug.push(data.allFile.edges[i].next.name)
      nextTitle.push(data.allFile.edges[i].next.childMdx.frontmatter.title)
    }
  }

  let nextI = nextSlug.indexOf(pageContext.projectName) + 1
  if (nextI >= data.allFile.edges.length) {
    nextI = 0
  }

  let creditsData = "<h4>Credits</h4><ul>"
  credits.forEach(element => {
    creditsData += "<li>" + element + "</li>"
  })
  creditsData += "</ul>"

  let linksData = ""
  let hideLinks = {}
  if (links.length !== 0) {
    linksData = "<h4>Links</h4><ul>"
    for (let i = 0; i < links.length; i = i + 2) {
      linksData +=
        '<li><a href="' + links[i + 1] + '">' + links[i] + "</a></li>"
    }
    linksData += "</ul>"
  } else {
    hideLinks = { margin: "0" }
  }

  return (
    <Layout theme={theme} currentPath="project-page">
      <Helmet>
        <title>{"VOLNA / " + title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={"https://volna-media.com" + metaImage} />
        <link
          rel="canonical"
          href={"https://volna-media.com/projects/" + pageContext.projectName}
        />

        <meta
          property="og:url"
          content={
            "https://volna-media.com/projects/" + pageContext.projectName
          }
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={"VOLNA / " + title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={"https://volna-media.com" + metaImage}
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="VOLNA" />
        <meta name="twitter:title" content={"VOLNA / " + title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={"https://volna-media.com" + metaImage}
        />
      </Helmet>

      <article>
        <ProjectPageHighlight
          highlightVideo={highlightVideo}
          highlightImage={highlightImage.childImageSharp.gatsbyImageData}
        />
        <div className="project-intro">
          <h2>{title}</h2>
          <p
            className="body-large"
            dangerouslySetInnerHTML={{ __html: summary }}
          />
        </div>

        <div className="project-content">
          <MDXRenderer>{data.file.childMdx.body}</MDXRenderer>
        </div>

        <div className="more-info">
          <div
            className="credits"
            dangerouslySetInnerHTML={{ __html: creditsData }}
          />
          <div
            className="project-links"
            dangerouslySetInnerHTML={{ __html: linksData }}
            style={hideLinks}
          />
          <div className="next-project">
            <span>Next project</span>
            <h4>
              <Link to={"/projects/" + nextSlug[nextI]}>
                {nextTitle[nextI]}
              </Link>
            </h4>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query ProjectsPage($projectName: String) {
    file(name: { eq: $projectName }) {
      childMdx {
        frontmatter {
          title
          description
          highlightVideo
          highlightImage {
            childImageSharp {
              resize(width: 2560) {
                src
              }
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
          summary
          theme
          credits
          links
        }
        body
      }
    }
    allFile(
      sort: { fields: childrenMdx___frontmatter___date, order: DESC }
      filter: { extension: { eq: "mdx" } }
    ) {
      edges {
        node {
          name
          childMdx {
            frontmatter {
              title
            }
          }
        }
        next {
          name
          childMdx {
            frontmatter {
              title
            }
          }
        }
      }
    }
  }
`
