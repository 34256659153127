import React, { forwardRef } from "react"
import VimeoPlayer from "../components/VimeoPlayer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Workcard = forwardRef(({ data, isVisible, isCurrent }, ref) => {
  const cardClass = ["work-card", "work-card-featured"]
  const image = getImage(data.imageThumb)
  const videoAvailable = Boolean(data.videoThumb > 0)

  const style = {
    display: isCurrent ? "block" : "none",
    visibility: isVisible && isCurrent ? "visible" : "hidden",
  }

  return (
    <div className={cardClass[data.featured]} ref={ref} style={style}>
      <a href={"/projects/" + data.slug}>
        <div className="work-card-media">
          <div className="work-image-content">
            <GatsbyImage image={image} alt="" loading="lazy" />
          </div>
          {videoAvailable && isVisible && isCurrent && (
            <div className="work-video-content">
              <VimeoPlayer videoID={data.videoThumb} showControls="0" />
            </div>
          )}
        </div>
        <div className="link-title">{data.title}</div>
        <div className="link-description">{data.description}</div>
      </a>
    </div>
  )
})

export default Workcard
