import { useState, useEffect } from "react"

const useVisibilityState = refs => {
  const [visibilityState, setVisibilityState] = useState(() =>
    refs.map(() => false)
  )

  useEffect(() => {
    if (!refs || refs.length === 0) return

    const observer = new IntersectionObserver(
      entries => {
        setVisibilityState(prevVisibilityState => {
          const updatedVisibilityState = [...prevVisibilityState]

          entries.forEach(entry => {
            const index = refs.indexOf(entry.target)
            if (index !== -1) {
              updatedVisibilityState[index] = entry.isIntersecting
            }
          })

          return updatedVisibilityState
        })
      },
      { rootMargin: "1800px" }
    )

    refs.forEach(ref => {
      if (ref) observer.observe(ref)
    })

    return () => {
      observer.disconnect()
    }
  }, [refs])

  return visibilityState
}

export default useVisibilityState
