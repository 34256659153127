import React, { useRef } from "react"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import WorkcardFullBleed from "../components/WorkCardFullBleed"
import useVisibility from "../hooks/useVisibility"

export default function Home({ data }) {
  const theme = "dark"
  const featuredProjects = data.allFile.edges.map(project => ({
    data: {
      title: project.node.childMdx.frontmatter.title,
      description: project.node.childMdx.frontmatter.description,
      videoThumb: project.node.childMdx.frontmatter.thumb,
      slug: project.node.name,
    },
    key: project.node.id,
  }))

  const projects = [
    { data: { videoThumb: "615195993" }, key: "highlights" },
  ].concat(featuredProjects)

  const refs = useRef([])
  const visibilityState = useVisibility(refs.current)

  return (
    <Layout theme={theme} currentPath="/">
      <Helmet>
        <title>{"VOLNA"}</title>
        <meta name="description" content="We fill your void" />
        <link rel="canonical" href="https://volna-media.com" />
        <meta property="og:url" content="https://volna-media.com" />
        <meta property="og:title" content="VOLNA" />
        <meta property="og:description" content="We fill your void" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="VOLNA" />
        <meta name="twitter:title" content="VOLNA" />
        <meta name="twitter:description" content="We fill your void" />
      </Helmet>

      {projects.map((project, i) => (
        <WorkcardFullBleed
          key={project.key}
          data={project.data}
          ref={node => (refs.current[i] = node)}
          isVisible={visibilityState[i]}
          isHighlight={i === 0 ? true : false}
        />
      ))}
      <div className="cta">
        <Link to="/works">
          <span className="body-large">View all works</span>
        </Link>
        <span className="body-large"> &rarr;</span>
      </div>
      <div className="main-about-text">
        <h2>
          VOLNA is an independent art collective shaping cutting-edge designs
          and spatial art using diverse media and technologies. From intricate
          light and kinetic objects to large-scale installations, we create
          unique environments that blend together physical and digital worlds
        </h2>
      </div>
      <div className="cta">
        <Link to="/about">
          <span className="body-large">More about VOLNA</span>
        </Link>
        <span className="body-large"> &rarr;</span>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FeaturedProjects {
    allFile(
      sort: { fields: childrenMdx___frontmatter___date, order: DESC }
      filter: {
        ext: { eq: ".mdx" }
        childMdx: { frontmatter: { mainFeatured: { eq: 1 } } }
      }
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              thumb
              title
              description
            }
          }
          name
        }
      }
    }
  }
`
