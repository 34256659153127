import React from "react"
import Layout from "../components/Layout"
import Helmet from "react-helmet"

export default function Contact() {
  const theme = "dark"
  return (
    <Layout theme={theme} currentPath="contact">
      <Helmet>
        <title>{"VOLNA / Contact"}</title>
        <meta name="description" content="Contact VOLNA" />
        <link rel="canonical" href="https://volna-media.com/contact" />
        <meta property="og:url" content="https://volna-media.com/contact" />
        <meta property="og:title" content="VOLNA" />
        <meta property="og:description" content="" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="VOLNA" />
        <meta name="twitter:title" content="VOLNA / Contact" />
        <meta name="twitter:description" content="" />
      </Helmet>

      <div className="static-page">
        <div className="contact-page-links">
          <h1>
            <a href="mailto:hey@volna-media.com">E-mail</a>
          </h1>
          <h1>
            <a href="https://instagram.com/keine_angst">Instagram</a>
          </h1>
          <h1>
            <a href="https://vimeo.com/vvvvolna">Vimeo</a>
          </h1>
          {/* <h1><a href='https://www.facebook.com/vvvvolna/'>Facebook</a></h1> */}
        </div>
        {/* <div className="studio-info">
                    <div className="studio-info-section">
                        <h3>Jobs and internship</h3>
                        <p>We do not currently have any open positions, but don't hesitate to contact us if you are interested in getting involved in our work.</p>
                    </div>
                </div> */}
      </div>
    </Layout>
  )
}
