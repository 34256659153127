import React, { forwardRef } from "react"
import VimeoPlayer from "../components/VimeoPlayer"

const WorkcardFullBleed = forwardRef(
  ({ data, isVisible, isHighlight }, ref) => {
    const visibilityClass = isVisible ? "" : " hidden"

    let content = (
      <>
        <a href={"/projects/" + data.slug}>
          <div className={"full-bleed" + visibilityClass}>
            <div className="video-highlight-fallback" />
            {isVisible && (
              <VimeoPlayer videoID={data.videoThumb} showControls="0" />
            )}
          </div>
          <div className="featured-work-info">
            <div className="link-title">{data.title}</div>
            <div className="link-description">{data.description}</div>
          </div>
        </a>
      </>
    )

    if (isHighlight) {
      content = (
        <>
          <div className={"full-bleed" + visibilityClass}>
            <div className="video-highlight-fallback" />
            {isVisible && (
              <VimeoPlayer videoID={data.videoThumb} showControls="0" />
            )}
          </div>
          <div className="main-intro-text">
            <span className="xl-heading">We fill your void</span>
          </div>
        </>
      )
    }

    return (
      <div className="main-featured-work" ref={ref}>
        {content}
      </div>
    )
  }
)

export default WorkcardFullBleed
