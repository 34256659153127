import { graphql } from "gatsby"
import React, { useState, useRef } from "react"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import WorkCard from "../components/WorkCard"
import useVisibility from "../hooks/useVisibility"

export default function Works({ data }) {
  const theme = "bright"
  const projects = data.allFile.edges.map(project => ({
    data: {
      title: project.node.childMdx.frontmatter.title,
      description: project.node.childMdx.frontmatter.description,
      featured: project.node.childMdx.frontmatter.featured,
      videoThumb: project.node.childMdx.frontmatter.thumb,
      imageThumb:
        project.node.childMdx.frontmatter.highlightImage.childImageSharp
          .gatsbyImageData,
      slug: project.node.name,
    },
    categories: project.node.childMdx.frontmatter.categories,
    key: project.node.id,
  }))

  const categoriesList = [
    { name: "everything", id: 0 },
    { name: "artworks", id: 1 },
    { name: "scenography", id: 2 },
    { name: "games", id: 3 },
    { name: "videos", id: 4 },
    { name: "cross-disciplinary", id: 5 },
  ]
  const [currentCategory, setCurrentCategory] = useState(categoriesList[0].name)

  const selectCategory = i => {
    setCurrentCategory(i)
  }

  const refs = useRef([])
  const visibilityState = useVisibility(refs.current)

  return (
    <Layout theme={theme} currentPath="works">
      <Helmet>
        <title>{"VOLNA / Works"}</title>
        <meta name="description" content="Selected works" />
        <link rel="canonical" href="https://volna-media.com/works" />
        <meta property="og:url" content="https://volna-media.com/works" />
        <meta property="og:title" content="VOLNA / Works" />
        <meta property="og:description" content="Selected works" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="VOLNA" />
        <meta name="twitter:title" content="VOLNA / Works" />
        <meta name="twitter:description" content="Selected works" />
      </Helmet>

      <div className="category-selector-wrapper">
        <div className="category-selector-limiter">
          <div className="category-selector small">
            {categoriesList.map(i => (
              <button
                type="button"
                key={i.id}
                onClick={() => {
                  selectCategory(i.name)
                }}
                className={i.name === currentCategory ? "current-category" : ""}
              >
                {i.name.charAt(0).toUpperCase() + i.name.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="work-category">
        {projects.map((project, i) => (
          <WorkCard
            data={project.data}
            key={project.key}
            isCurrent={
              project.categories.includes(currentCategory) ||
              currentCategory === "everything"
            }
            isVisible={visibilityState[i]}
            ref={node => (refs.current[i] = node)}
          />
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectsList {
    allFile(
      filter: { ext: { eq: ".mdx" } }
      sort: { fields: childrenMdx___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          name
          id
          childMdx {
            frontmatter {
              categories
              description
              featured
              title
              thumb
              highlightImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 3840
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
