import React from "react"
import VimeoPlayer from "../components/VimeoPlayer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function ProjectPageHighlight({
  highlightVideo,
  highlightImage,
}) {
  let content = <div></div>
  const image = getImage(highlightImage)
  if (highlightVideo === 0) {
    content = (
      <div className="image-highlight">
        <GatsbyImage image={image} alt="" />
      </div>
    )
  } else {
    content = (
      <div className="video-highlight">
        <div className="video-highlight-fallback" />
        <VimeoPlayer videoID={highlightVideo} showControls="0" />
      </div>
    )
  }

  return <div>{content}</div>
}
