import React, { useState } from "react"

export default function VimeoPlayer({ videoID, showControls }) {
  const controls = ["?background=1", ""]

  const [style, setStyle] = useState({ opacity: 0 })

  const changeStyle = () => {
    setStyle({ opacity: 1 })
  }

  return (
    <iframe
      title={videoID}
      loading="lazy"
      src={"https://player.vimeo.com/video/" + videoID + controls[showControls]}
      allowFullScreen
      allowtransparency="true"
      style={style}
      onLoad={changeStyle}
    ></iframe>
  )
}
